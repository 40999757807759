import React, {useEffect, useState} from "react";
import MainVisual01 from "../resources/img/main_visual01.jpg";
import BlankImage from "../resources/img/blank_img.png";
import "../resources/css/style.css";
import "../resources/css/slick.css";
import "../resources/css/slick-theme.css";
import $ from "jquery";
import ProductList from "../resources/data/product/productList.json";
import PartnerList from "../resources/data/main/partner.json";
import Slider from 'react-slick';

export default function Index() {
    let [productList, setProductList] = useState(null);
    let [partnerList, setPartnerList] = useState(null);

    // 20240910 김성민 팝업 기간 추가
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const today = new Date();
        const startDate = new Date(2024, 8, 10);
        const endDate = new Date(2024, 8, 18);

        // 팝업 생성 기간 확인
        if (today >= startDate && today <= endDate) {
            setIsVisible(true);
        }
    }, []);

    const productSettings = {
        centerMode: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
    };

    const partnerSettings = {
        centerMode: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 3,
        arrows: true,
    };

    useEffect(() => {
        setProductList(ProductList.products.sort(function (a, b) {
            if (a.prdcode > b.prdcode) {
                return -1;
            } else if (a.prdcode > b.prdcode) {
                return 1;
            }
            return 0;
        }).filter((item) => item.prdtype === "vending"));
        setPartnerList(PartnerList.partners);
    }, []);

    $(function () {
        popupConfig("10");
        popupConfig("20");
    });

    //공지사항 팝업용 2022.08.11 twkim
    function popupConfig(id) {
        if (getCookie("popup" + id) != "Y") {
            $(".popup" + id).removeClass("hidePopup");
            $(".popup" + id).addClass("showPopup");
            let top = '2%';
            let left = ($(window).scrollLeft() + ($(window).width() - $('.popup' + id).width()) / 2);

            $(".popup" + id)
                .css("width", "auto")
                .css("height", "auto")
                .css("top", top)
                .css("left", left)
                .css("position", "absolute")
                .css("z-index", "99");
            $(".popup" + id + " img").css("min-width", "100%").css("height", "auto");
            $(".popup" + id + " jumpPost").css("height", "90%");
        }
    }


    function setCookie(name, value, expiredays) {
        let todayDate = new Date();
        todayDate.setDate(todayDate.getDate() + expiredays);
        document.cookie = name + "=" + escape(value) + "; path=/; expires=" + todayDate.toGMTString() + ";"
    }
    function getCookie(name) {
        var nameOfCookie = name + "=";
        let endOfCookie;
        var x = 0;
        while (x <= document.cookie.length) {
            var y = (x + nameOfCookie.length);
            if (document.cookie.substring(x, y) == nameOfCookie) {
                if ((endOfCookie = document.cookie.indexOf(";", y)) == -1)
                    endOfCookie = document.cookie.length;
                return unescape(document.cookie.substring(y, endOfCookie));
            }
            x = document.cookie.indexOf(" ", x) + 1;
            if (x == 0)
                break;
        }
        return "";
    }
    function closePopup(type, id) {
        if (type == 1) {
            setCookie("popup" + id, "Y");
        }
        $(".popup" + id).removeClass("showPopup");
        $(".popup" + id).addClass("hidePopup");
    }
    return (
        <>
            {/*메인(첫 화면)*/}
            {/* 모바일 Burger Menu */}
            <link rel="stylesheet" type="text/css" href="/comm/css/main.css"/>
            <link rel="stylesheet" type="text/css" href="/comm/css/modal.css"/>
            <div id="visual" role="toolbar" style={{opacity: "1"}}>
                <div className="video_box slider">
                    <div>
                        <img src={MainVisual01} alt={"cross"}/>
                    </div>
                </div>
                <div className="v_txt" style={{opacity: "1"}}>
                    <h3 style={{opacity: "1"}}>Ubiquitous<br/>Communication</h3>
                    <p style={{opacity: "1"}}>지불, 결제시스템의 변화와 더불어 진화를 거듭해온 유비씨엔</p>
                    <div className={"chatButton"} style={{marginTop: "5%"}}>
                        <a href={"http://pf.kakao.com/_Ihxlbj"} target="_blank">
                            <img src={"https://image.ubcn.co.kr/ic_talk2.png"}/>
                        </a>
                    </div>
                </div>
                {/* 2023.06.07 twkim P&P사업부 요청에 의해 내비게이션바 추가 */}
                <div className="main_bg">
                        <div className="applicationMenu">
                            <ul>
                                <li>
                                    <a href="https://biz.ubcn.co.kr/biz_regist" target="_blank">
                                        <img src="https://image.ubcn.co.kr/홈페이지//ic01.png"/><br/>
                                        <span>신규가맹신청</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://biz.ubcn.co.kr/term_regist" target="_blank">
                                        <img src="https://image.ubcn.co.kr/홈페이지//ic02.png"/><br/>
                                        <span>단말기신청</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://biz.ubcn.co.kr/term_change" target="_blank">
                                        <img src="https://image.ubcn.co.kr/홈페이지//ic03.png"/><br/>
                                        <span>명의변경신청</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://biz.ubcn.co.kr/term_quit" target="_blank">
                                        <img src="https://image.ubcn.co.kr/홈페이지//ic04.png"/><br/>
                                        <span>해지신청</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://biz.ubcn.co.kr/npay_regist" target="_blank">
                                        <img src="https://image.ubcn.co.kr/홈페이지//ic05.png"/><br/>
                                        <span>네이버페이 가맹신청</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://biz.kakaopay.com/applications" target="_blank">
                                        <img src="https://image.ubcn.co.kr/홈페이지//ic07.png"/><br/>
                                        <span>카카오페이 가맹신청</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                </div>
                {/*<div id={"redirectSubMenuRow"}>
                    <div id={"redirectSubMenuArea"} style={{width: "70%", margin: "auto"}}>
                        <div id={"redirectSubMenuBox"}>
                            <img src={"https://image.ubcn.co.kr/홈페이지/ic01.png"}/>
                            <span>신규가맹신청</span>
                        </div>
                        <div id={"redirectSubMenuBox"}>
                            <img src={"https://image.ubcn.co.kr/홈페이지/ic02.png"}/>
                            <span>단말기신청</span>
                        </div>
                        <div id={"redirectSubMenuBox"}>
                            <img src={"https://image.ubcn.co.kr/홈페이지/ic03.png"}/>
                            <span>명의변경신청</span>
                        </div>
                        <div id={"redirectSubMenuBox"}>
                            <img src={"https://image.ubcn.co.kr/홈페이지/ic04.png"}/>
                            <span>해지신청</span>
                        </div>
                        <div id={"redirectSubMenuBox"}>
                            <img src={"https://image.ubcn.co.kr/홈페이지/ic05.png"}/>
                            <span>네이버페이 가맹신청</span>
                        </div>
                        <div id={"redirectSubMenuBox"}>
                            <img src={"https://image.ubcn.co.kr/홈페이지/ic06.png"}/>
                            <span>티머니 가맹신청</span>
                        </div>
                        <div id={"redirectSubMenuBox"}>
                            <img src={"https://image.ubcn.co.kr/홈페이지/ic07.png"}/>
                            <span>카카오페이 가맹신청</span>
                        </div>
                        <div id={"redirectSubMenuBox"}>
                            <img src={"https://image.ubcn.co.kr/홈페이지/ic08.png"}/>
                            <span>페이코 가맹신청</span>
                        </div>
                    </div>
                </div>*/}
                {/*<div className="scroll_down" style={{opacity: "1"}}>
                    <a href="#contents" className="mouse_wheel _mPS2id-h"><i className="actions"></i></a>
                </div>*/}
            </div>
            {/*내용*/}
            <div id="contents" className="_mPS2id-t">
                <div className="product_con">
                    <h3 className="m_tit">PRODUCT</h3>
                    <p className="m_txt">유비씨엔(주)는 U-Payment 시장에 앞장서고 있습니다.</p>
                    <br/>
                    {/*실제컨텐츠영역*/}
                    <div className="contents_in">
                        <div className="width1180">
                            <link href="/data/product2/skin/prdBasic/style.css" rel="stylesheet" type="text/css"/>
                            <div className="pro_list">
                                <Slider {...productSettings}>
                                {productList && productList.map(item => (
                                    <dl className="product_vending" key={item.prdcode}>
                                        <a href={`/productDetail/${item.prdcode}`}>
                                            <dt>
                                                <img src={BlankImage}
                                                     style={{backgroundImage: `url(/data/product2/${item.prdimg_R})`}}
                                                     alt=""/></dt>
                                            <dd>
                                                <p className="title">{item.prdname}</p>
                                            </dd>
                                        </a>
                                    </dl>
                                ))}
                                </Slider>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="business_con">
                    <div className="business_in about_con">
                        <div className="bu_img">About <span className="exbold">UBCn</span></div>
                        <div className="bu_txt">
                            <img src="/img/about_icon.png" alt="RF/NFC 결제단말기 회사"/>
                            <h6>
                                <span className="ls0">IC/RF/NFC/QR</span>&nbsp;지불 솔루션 회사
                            </h6>
                            <p className="m_txt">유비씨엔은 지불, 결제시스템의 변화와 더불어 변화와 진화를 <br/>거듭해 성장하고 있는 IC/RF/NFC/QR
                                지불 솔루션 회사입니다.</p>
                            <a href="/product/total"
                               className="moreBtn"
                               target="_self">MORE</a>
                        </div>
                    </div>
                    <div className="business_in solution_con">
                        <div className="bu_img exbold">SOLUTION</div>
                        <div className="bu_txt">
                            <img src="/img/solution_icon.png" alt="결제 솔루션 서비스 제공"/>
                            <h6>결제 솔루션 서비스 제공</h6>
                            <p className="m_txt">유비씨엔은 혁신적인 제품과 스마트카드 관련 결제 솔루션 서비스를 <br/>제공함으로써 새로운 가치를 창출해가고
                                있습니다.</p>
                            <a href="/service/umms" className="moreBtn" target="_self">MORE</a>
                        </div>
                    </div>
                </div>
                <div className="quick_con">
                    <div className="width1180">
                        <dl>
                            <a href="/customer/notice">
                                <dt><i></i></dt>
                                <dd>
                                    <h6>공지사항</h6>
                                    <p>드라이버 및 각종 자료를 <br/>다운받으세요.</p>
                                    <span>MORE</span>
                                </dd>
                            </a>
                        </dl>
                        <dl>
                            <a href="/customer/qna">
                                <dt><i></i></dt>
                                <dd>
                                    <h6>문의하기</h6>
                                    <p>궁금하신 사항을 <br/>문의해 주세요.</p>
                                    <span>MORE</span>
                                </dd>
                            </a>
                        </dl>
                        <dl>
                            <a href="/company/location">
                                <dt><i></i></dt>
                                <dd>
                                    <h6>찾아오시는 길</h6>
                                    <p>유비씨엔(주)으로 <br/>찾아오시는 길을 안내드립니다.</p>
                                    <span>MORE</span>
                                </dd>
                            </a>
                        </dl>
                    </div>
                </div>
            </div>

            {/*파트너사*/}
            <div className="client_con">
                <ul className="clientList slick-initialized slick-slider">
                    <Slider {...partnerSettings}>
                        {partnerList && partnerList.map(item => (
                            <li className="slick-slide slick-cloned" data-slick-index="-5" aria-hidden="true"
                                tabIndex="-1" style={{width: "204px"}}>
                                <a href={`${item.partnerUrl}`} target="_blank" rel="noreferrer" tabIndex="-1">
                                    <img src="/img/blank_img2.png" style={{backgroundImage: `url(${item.partnerImage})`}}/></a>
                            </li>
                        ))}
                    </Slider>
                </ul>ㅇ
            </div>

            {/*10번 팝업*/}
            {isVisible && (
            <div className="modalDiv popup10 hidePopup">
                <img src="https://image.ubcn.co.kr/%EA%B3%B5%EC%A7%80%EC%82%AC%ED%95%AD/vmms/2024_chuseok.jpg" className="popImage"
                     style={{height: "500px", minWidth: "350px", marginLeft: "-0.5px", marginTop: "20px"}}/>
                <div className="jumpPost"></div>
                <div className="modalFooter" style={{marginLeft: "-0.5px"}}>
                    <a className="closeForDay" onClick={() => closePopup(1, '10')}>다시보지 않기</a>
                    <a className="closeThisTime" onClick={() => closePopup(2, '10')}>닫기</a>
                </div>
            </div>
            )}
            {/*20번 팝업*/}
            {/*<div className="modalDiv popup20 hidePopup">
                <img src="https://image.ubcn.co.kr/230113_popup.jpg" className="popImage"
                     style={{height: "500px", minWidth: "350px", marginLeft: "-0.5px", marginTop: "-0.5px"}}/>
                <div className="jumpPost"></div>
                <div className="modalFooter" style={{marginLeft: "-0.5px"}}>
                    <a className="closeForDay" onClick={() => closePopup(1, '20')}>다시보지 않기</a>
                    <a className="closeThisTime" onClick={() => closePopup(2, '20')}>닫기</a>
                </div>
            </div>*/}

        </>
    );
}

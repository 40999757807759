export default function Footer() {
    return (
        <div id="footer">
            <div className="foot_in">
                <div className="f_logo"><img src="/img/logo.png" alt="로고"/></div>
                <div className="com_info">
                    <ul className="foot_bn">
                        <li><a href="/company/about">회사소개</a></li>
                        <li><a href="/company/location">찾아오시는 길</a></li>
                        <li><a href="/other/sitemap">사이트맵</a></li>
                        <li><a href="/other/privacy">개인정보취급방침</a></li>
                    </ul>
                    <div className="com_info_in">
                        <span>유비씨엔(주)</span>
                        <span>대표자 : 김대식</span>
                        <span>사업자등록번호 : 119-86-09522</span>
                        <span>영업문의 : 02-6458-2000</span>
                        <span>팩스 : 02-6458-2001</span>
                        <br/>
                        <span>주소 : 서울특별시 금천구 가산디지털1로 212, 910호(가산동, 코오롱 애스턴)   sales@ubcn.co.kr</span>
                    </div>

                    <div className="com_tel show980">
                        <h6>
                            <img src="/img/foot_tel.png" alt="call"/>
                            영업문의 <a href="tel:02-6458-2000" className="call">02-6458-2000 </a>
                        </h6>
                        <h6>
                            <img src="/img/foot_tel.png" alt="call"/>
                            서비스센터<a href="tel:1544-5737" className="call">1544-5737</a>
                        </h6>
                        <p>평일 09:15-17:45 (점심 11:30-13:00) / 휴일 : 토・일・공휴일</p>
                    </div>

                    <p className="copyright">Copyright © 유비씨엔(주) All Rights Reserved. Hosting by UBCn System</p>
                </div>
                <div className="com_tel none980">
                    <h6>
                        <img src="/img/foot_tel.png" alt="call"/>
                        영업문의<a href="tel:02-6458-2000" className="call">02-6458-2000</a>
                    </h6>
                    <h6>
                        <img src="/img/foot_tel.png" alt="call"/>
                        서비스센터<a href="tel:1544-5737" className="call">1544-5737</a>
                    </h6>
                    <p>평일 09:15-17:45 (점심 11:30-13:00) / 휴일 : 토・일・공휴일</p>
                </div>
            </div>
            <div className="topBtn">
                {/*<a href="http://pf.kakao.com/_Ihxlbj" target="_blank" id="scroll">
                    <img src={"https://image.ubcn.co.kr/ic_talk.png"}/>
                </a>*/}
                <a href="http://pf.kakao.com/_Ihxlbj" target="_blank">
                    <img src={"https://image.ubcn.co.kr/ic_talk.png"}/>
                </a>
                <a href="#top" id="scroll"></a>
            </div>
        </div>
    );
}

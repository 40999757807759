import React from "react";
import $ from "jquery";

export default function Header() {
    function termChange() {
        alert("현재 준비중인 기능입니다. \n 해당 기능은 sales@ubcn.co.kr 로 문의 부탁드립니다.");
    }

    function adjustStyle(width) {
        if (width < 981) {
            $(".depth2").addClass("dlst");
            $(".depth2").removeClass("depth2");
        } else {
            $(".depth2").removeClass("dlst");
            $(".dep2 .dropLst > div").addClass("depth2");
        }
    }

    /*메인메뉴 gnb*/
    $(function () {
        $("#gnb").mouseover(function () {
            $("#header").addClass("on");
        }).mouseleave(function () {
            $("#header").removeClass("on");
        });
        $('.bg').mouseover(function () {
            $('#header').addClass('on');
        });
        $('.bg').mouseout(function () {
            $('#header').removeClass('on');
        });

        adjustStyle($(this).width());
        $(window).resize(function () {
            adjustStyle($(this).width());
        });
    });

    return (
        <div id="header" className="">
            <div className="header_in">
                <h1><a href="/" to={"/"}></a></h1>
                <div className="topUtil">
                    {/*<div className="molink3">
                        <a href="https://biz.ubcn.co.kr/term_change" onClick={() => termChange()} rel="noreferrer"
                           target="_blank">단말기 명의변경 신청서</a>
                    </div>
                    <div className="molink2">
                        <a href="https://biz.ubcn.co.kr/term_regist" onClick={() => termRegist()} rel="noreferrer"
                           target="_blank">단말기 신청서</a>
                    </div>*/}
                    <div className="menuBtn">
                        <a className="btnMenu_m"></a>
                        <a href="/other/sitemap"></a>
                    </div>
                </div>
            </div>
            {/* 메뉴 */}
            <div id="gnb">
                <div id="menu">
                    <ul className="menu">
                        <li><span className="gnb_1depth"><a href="/product/total">제품</a></span>
                            <div className="menu_depth">
                                <ul>
                                    <li><a href="/product/total">Total</a></li>
                                    <li><a href="/product/vending">Vending</a></li>
                                    <li><a href="/product/access_control">Access Control</a></li>
                                    <li><a href="/product/ic_reader">IC Reader</a></li>
                                    <li><a href="/product/rf_reader">RF Reader</a></li>
                                    <li><a href="/product/multipad">MultiPAD</a></li>
                                    <li><a href="/product/afc">AFC</a></li>
                                </ul>
                            </div>
                        </li>
                        <li><span className="gnb_1depth"><a href="/service/umms">서비스</a></span>
                            <div className="menu_depth">
                                <ul>
                                    <li><a href="/service/umms">무인기기 관리</a></li>
                                    <li><a href="/service/ecps">사원카드 결제</a></li>
                                    <li><a href="/service/usms">신용카드 출입</a></li>
                                </ul>
                            </div>
                        </li>
                        <li><span className="gnb_1depth"><a href="/customer/notice">고객지원</a></span>
                            <div className="menu_depth">
                                <ul>
                                    <li><a href="/customer/notice">공지사항</a></li>
                                    <li><a href="/customer/qna">소통의 장</a></li>
                                    <li><a href="/customer/result">설치사례</a></li>
                                    <li><a href="https://file.ubcn.co.kr/%EA%B3%B5%EC%A7%80%EC%82%AC%ED%95%AD/vmms/OLD I-ZEN Driver File.zip"
                                           target="_blank" download="I-ZEN Driver(구).zip">I-ZEN Driver(구)</a></li>
                                    <li><a href="https://file.ubcn.co.kr/%EA%B3%B5%EC%A7%80%EC%82%AC%ED%95%AD/vmms/NEW I-ZEN Driver File.zip"
                                           target="_blank" download="I-ZEN Driver(신).zip">I-ZEN Driver(신)</a></li>
                                </ul>
                            </div>
                        </li>
                        <li><span className="gnb_1depth"><a href="/company/about">회사소개</a></span>
                            <div className="menu_depth">
                                <ul>
                                    <li><a href="/company/about">기업개요</a></li>
                                    <li><a href="/company/history">연혁</a></li>
                                    <li><a href="/company/partner">고객사</a></li>
                                    <li><a href="/company/location">찾아오시는 길</a></li>
                                </ul>
                            </div>
                        </li>
                        {/* 2023.06.07 twkim P&P사업부 요청에 의해 내비게이션 내용 추가 */}
                        <li><span className="gnb_1depth"><a href="#">온라인 신청서</a></span>
                            <div className="menu_depth">
                                <ul>
                                    <li><a href="https://biz.ubcn.co.kr/biz_regist"
                                        /*onClick={() => termRegist()} */rel="noreferrer"
                                           target="_blank">신규가맹신청</a></li>
                                    <li><a href="https://biz.ubcn.co.kr/term_regist"
                                        /*onClick={() => termRegist()} */rel="noreferrer"
                                           target="_blank">단말기신청</a></li>
                                    <li><a href="https://biz.ubcn.co.kr/term_change"
                                        /*onClick={() => termChange()} */ rel="noreferrer"
                                           target="_blank">명의변경신청</a></li>
                                    <li><a href="https://biz.ubcn.co.kr/term_quit"
                                        /*onClick={() => termQuit()}*/ rel="noreferrer"
                                           target="_blank">해지신청</a></li>
                                    <li><a href="https://biz.ubcn.co.kr/npay_regist"
                                        /*onClick={() => termNaver()}*/ rel="noreferrer"
                                           target="_blank">네이버페이 가맹신청</a></li>
                                    <li><a href="https://biz.kakaopay.com/applications"
                                        /*onClick={() => termNaver()}*/ rel="noreferrer"
                                           target="_blank">카카오페이 가맹신청</a></li>
                                </ul>
                            </div>
                        </li>
                        {/*<li className={"molink2"} style={{width: "200px", position: "absolute", marginLeft: "8%"}}>
                            <span className="gnb_1depth">
                            <a href="https://biz.ubcn.co.kr/term_change"
                               onClick={() => termChange()} rel="noreferrer"
                               target="_blank">단말기 명의변경 신청서</a></span>
                        </li>
                        <li className={"molink3"} style={{position: "absolute", marginLeft: "19.7%"}}>
                            <span className="gnb_1depth">
                            <a href="https://biz.ubcn.co.kr/term_regist"
                               onClick={() => termRegist()} rel="noreferrer"
                               target="_blank">단말기 신청서</a></span>
                        </li>*/}
                    </ul>
                </div>
            </div>
            <div className="bg"></div>
        </div>
    );
}

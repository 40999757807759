import {useParams, useNavigate} from "react-router-dom";
import $ from "jquery";
import React, {useEffect, useState} from "react";
import Parser from "html-react-parser";
import ServiceData from "../../resources/data/service/service.json";

export default function Service() {
    let navigate = useNavigate();
    let [items, setItems] = useState(null);
    let params = useParams();

    //페이지 접근 가능한 페이지 지정
    const [pages] = useState(["umms", "ecps", "usms"]);
    useEffect(() => {
        if (pages.includes(params.type.toLowerCase())) {
            setItems(ServiceData.service.filter((item) => item.page === params.type.toLowerCase()))
        }else{
            navigate("/service/umms")
        }
        imgHandler(params.type);
    }, []);

    function imgHandler(params) {
        switch (params) {
            case 'umms':
                $('.pro_list > dl').show();
                $('.depth2 > ul > li > a').removeClass("on");
                $('.depth2 > ul > li:eq(0) > a').addClass("on");
                break;
            case 'ecps':
                $('.pro_list > dl').hide();
                $('.vending').show();
                $('.depth2 > ul > li > a').removeClass("on");
                $('.depth2 > ul > li:eq(1) > a').addClass("on");
                break;
            case 'usms':
                $('.pro_list > dl').hide();
                $('.access_control').show();
                $('.depth2 > ul > li > a').removeClass("on");
                $('.depth2 > ul > li:eq(2) > a').addClass("on");
                break;
        }
    }

    return (
        <>
            <link rel="stylesheet" type="text/css" href="/data/product2/skin/prdBasic/style.css"/>
            <link rel="stylesheet" type="text/css" href="/comm/css/sub.css"/>
            <div id="visual" className="subtop_product" style={{opacity: "1"}}>
                <p></p>
                <h3><span>서비스</span>SERVICE</h3>
            </div>

            <div id="contents" style={{opacity: "1"}}>
                <div id="sticker">
                    <article className="NavMenu">
                        <div>
                            <nav className="mobile_snb">
                                <div className="tbox clearfix">
                                    <a href="/" className="home"></a>
                                    <div className="box dep1">
                                        <div className="dropLst">
                                            <a href="#" className="txt title">서비스</a>
                                            <div className="dlst">
                                                <ul>
                                                    <li><a href="/product/total" className="">제품</a></li>
                                                    <li><a href="/service/umms" className="">서비스</a></li>
                                                    <li><a href="/company/about" className="">회사소개</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box dep2">
                                        <div className="dropLst">
                                            <a href="#" className="txt title">{params.type.toUpperCase()}</a>
                                            <div className="depth2">
                                                <ul>
                                                    <li>
                                                        <a href={"/service/umms"}>무인기기 관리</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/service/ecps"}>사원카드 결제</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/service/usms"}>신용카드 출입</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </article>
                </div>
                {items && items.map(item => (
                <div className="contentArea_wide">
                    <div className="tit_area">
                        <div className="location">
                            <a href="/"><img src="/img/lnbHome.png" alt="Home"/></a>
                            <img src="/img/lnbArrow.png" alt=">"/>
                            <a href="/service/umms" className="here_link">서비스</a><img
                            src="/img/lnbArrow.png" alt=">"/>{item.title}
                        </div>
                        <h3 className="subcon_title">{item.title}</h3>
                    </div>


                    <div className="contents_in">
                        <div className="service_con umms">
                            <div className="service_top width1180">
                                <p><br/></p>
                                {Parser(item.urlButton)}
                                <div className="top_img">&nbsp;</div>
                                <div className="top_img">
                                    <img
                                        src={item.mainImage}
                                        alt="umms_img.jpg"/></div>
                                <div className="top_txt">
                                    <h5 className="sub_stitle bold">{item.subTitle}</h5>
                                    <p className="eng blue medium">{item.engSubTitle}</p>
                                </div>
                            </div>
                            <div className="service_icon width1180">
                                <p className="sub_txt">{item.content}</p>
                                <ul className="service_iconList">
                                    {Parser(item.serviceIconList)}
                                </ul>
                            </div>
                            <div className="service_spec contentBox">
                                <div className="width1180">
                                    <h5 className="sub_stitle bold">특징</h5>
                                    <ul className="checkBox">
                                        {Parser(item.serviceSpec)}
                                    </ul>
                                </div>
                            </div>
                            {Parser(item.serviceProdcuts)}
                        </div>
                    </div>
                </div>
                ))}
            </div>
        </>
    )
        ;
}